import { useEffect, useState } from "react";

import metaverse from "../assets/metaverse.png"
import metaverseDraft from "../assets/metaverse-map.png"

const Gallery = () => {

  return (
    <div className="metaverse">
      <div className="section-1">
        <div className="container">
          <img src={metaverse} className="title-img"/>
          <div className="draft-wrapper">
            <img src={metaverseDraft}/>
          </div>
          <p className="text-sm text-center">
            *Disclaimer: This is the first outlook of our metaverse. None of these are finalized. Suggestions are welcome to help us build a super fun and playful virtual Dickcatz world.
          </p>
        </div>
      </div>
      
    </div>
  )

}

export default Gallery;