export const sampleImageData = [
  {
    "image":require("../assets/909 1.jpg").default,
    "caption":"ADOPT THIS CAT"
  },
  {
    "image":require("../assets/732 1.jpg").default,
    "caption":"ADOPT THIS CAT"
  },
  {
    "image":require("../assets/752 1.jpg").default,
    "caption":"ADOPT THIS CAT"
  },
  {
    "image":require("../assets/818 1.jpg").default,
    "caption":"ADOPT THIS CAT"
  },
  {
    "image":require("../assets/862 1.jpg").default,
    "caption":"ADOPT THIS CAT"
  },
  {
    "image":require("../assets/881 1.jpg").default,
    "caption":"ADOPT THIS CAT"
  },
  {
    "image":require("../assets/917 1.jpg").default,
    "caption":"ADOPT THIS CAT"
  },
  {
    "image":require("../assets/961 1.jpg").default,
    "caption":"ADOPT THIS CAT"
  },
  {
    "image":require("../assets/969 1.jpg").default,
    "caption":"ADOPT THIS CAT"
  },
]