import { useEffect, useState } from "react";

import metaverse from "../assets/metaverse.png"
import metaverseDraft from "../assets/dickcatsmap2-01.png"

const LicensingAgreement = () => {

  return (
    <div className="licensing-agreement">
      <div className="section-1">
        <div className="container">
          <h1 className="title-lg text-center">
            LICENSING AGREEMENT
          </h1>
          <p className="text-md">
            THIS AGREEMENT (the "Agreement") is made and entered into effective as of the date of purchase (the "Effective Date"), by and between the creator of Dickcatz (hereinafter known as the "Licensor") and the NFT Buyer (hereinafter known as the "Licensee") of the copyrightable permissible work ("Permissible Work").
          </p>
          <h2 className="title-md bold">
            DEFINITIONS
          </h2>
          <p className="text-md text-pre-line">
            "Agreement" means the entire content of this document.
          </p>
          <p className="text-md text-pre-line">
            "Dickcatz" shall mean and refer to Licensor's creation of a unique digital collectible, also known as a NFT, that is managed entirely by the Solana network and the respective smart contract on that network.
          </p>
          <p className="text-md text-pre-line">
            "NFT" shall mean any blockchain-tracked, non-fungible token, also referred to as a 'token.'
          </p>
          <p className="text-md text-pre-line">
            "Smart Contract" shall mean lines of code or a transaction protocol that is intended to automatically execute, control or document basic relevant events and actions according to the terms of an agreement. The code and the agreements contained therein exist across a distributed, decentralized blockchain network.
          </p>
          <p className="text-md text-pre-line">
            "Permissible Work" shall mean the visual, literary, dramatic, artistic, and subject-matter works and content tied to the Dickcatz NFT.
          </p>
          <h2 className="title-md bold">
            LICENSE
          </h2>
          <p className="text-md text-pre-line">
            Ownership Rights. Licensor represents to be the sole author and creator of the Permissible Work and that the Permissible Work is an original work. Licensor agrees to have the sole and exclusive right to enter into this Agreement and the full warrant and authority to grant the rights granted hereby.
          </p>
          <p className="text-md text-pre-line">
            Non-Exclusive. Licensee acknowledges and agrees that the license granted herein is non-exclusive and that Licensor may license others to use the Permissible Work. Licensor shall have the right to assign and/or license its rights and obligations under this Agreement and all its right, title, and interest in the Permissible Work without the consent of Licensee.
          </p>
          <p className="text-md text-pre-line">
            Scope. Licensor hereby grants to Licensee, in accordance with the terms and conditions of this Agreement, a non-exclusive license to use the Permissible Work in the course of personal and commercial use and purposes. The license covers the non-exclusive right to reproduce, sell and distribute the Permissible Work, including reprints, translations, photographic reproductions, microform, electronic form (offline, online), or any other reproductions of similar nature in accordance with the terms of this Agreement. Licensor grants you a non-exclusive worldwide license to use, copy, and display the purchased Art for the purpose of creating derivative works based upon the Art
          </p>
          <p className="text-md text-pre-line">
            Assignment. This Agreement (including, without limitation, the license granted hereunder) is personal to Licensee and shall not be assigned or transferred by Licensee, except to a new purchaser of all or substantially all of the Permissible Work licensed to the Licensee. Any other attempt on the part of Licensee to assign, sub-license, or transfer Licensee's rights under this Agreement, except as provided herein, shall be invalid and void.
          </p>
          <p className="text-md text-pre-line">
            Accordance. Licensee desires to obtain, and Licensor has agreed to transfer to and authorize the use of the Permissible Work by Licensee in accordance with the terms and conditions of this Agreement. Licensee's purchase and use of the Permissible Work, in whole or in part, indicates their assent to the terms and conditions of this Agreement; and acknowledgment to have read and be legally bound by the terms of this Agreement.
          </p>
          <p className="text-md text-pre-line">
            Acknowledged and agreed upon by the creators of Dickcatz, JAN 2021.
          </p>
        </div>
      </div>
      
    </div>
  )

}

export default LicensingAgreement;