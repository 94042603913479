import twitterYellow from "../assets/twitter-yellow.svg";
import discordYellow from "../assets/discord-yellow.svg";
import instagramYellow from "../assets/instagram-yellow.svg";

import awards01 from "../assets/Dickcatz-AwardWinner.svg"
import awards02 from "../assets/Dickcatz-BestCatAward.svg"
import awards03 from "../assets/Dickcatz-GoldAward.svg"
import awards04 from "../assets/Dickcatz-OfficialSelection.svg"


const Nav = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="awards-wrapper">
          <img src={awards01} alt="Award"/>
          <img src={awards02} alt="Award"/>
          <img src={awards03} alt="Award"/>
          <img src={awards04} alt="Award"/>
        </div>
        <p className="text-sm color-pr text-center disclaimer">
          *Disclaimer: all the awards above are not real. We just want to give ourselves awards for fun.
        </p>
        <h3 className="title-md color-pr text-center">
          FOLLOW US ON
        </h3>
        <div className="social-media-wrapper">
          <a href="https://twitter.com/dickcatz" target="_blank" rel="noreferrer">
            <img src={twitterYellow} alt=""/>
          </a>
          <a href="https://discord.gg/dickcatz" target="_blank" rel="noreferrer">
            <img src={discordYellow} alt=""/>
          </a>
          <a href="https://www.instagram.com/dickcatz/" target="_blank" rel="noreferrer">
            <img src={instagramYellow} alt=""/>
          </a>
        </div>
        <p className="text-md color-pr text-center">
          Dickcatz © All Rights Reserved 2022
        </p>
      </div>
    </div>
  )
}

export default Nav;