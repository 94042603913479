import { useMemo, useEffect } from 'react';
import * as anchor from '@project-serum/anchor';

import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";


import Nav from "./components/nav";
import Footer from "./components/footer";

import Mint from "./pages/Mint";
import Home from "./pages/Home";
import Gallery from "./pages/Gallery";
import LicensingAgreement from "./pages/LicensingAgreement";
import Faq from "./pages/Faq";
import Roadmap from "./pages/Roadmap";
import Metaverse from "./pages/Mateverse";
import NotFound from "./pages/NotFound";

import { TransitionGroup, SwitchTransition, CSSTransition } from "react-transition-group";


import { clusterApiUrl } from '@solana/web3.js';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
} from '@solana/wallet-adapter-wallets';

import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react';
import { WalletDialogProvider } from '@solana/wallet-adapter-material-ui';

import { ThemeProvider, createTheme } from '@material-ui/core';

const theme = createTheme({
  palette: {
    type: 'dark',
  },
});

const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
  try {
    const candyMachineId = new anchor.web3.PublicKey(
      process.env.REACT_APP_CANDY_MACHINE_ID!,
    );

    return candyMachineId;
  } catch (e) {
    console.log('Failed to construct CandyMachineId', e);
    return undefined;
  }
};

const candyMachineId = getCandyMachineId();
const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;
const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost
  ? rpcHost
  : anchor.web3.clusterApiUrl('devnet'));

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);
const txTimeoutInMilliseconds = 30000;

const App = () => {

  useEffect(()=>{
    (async()=>{
      const preloader = await document.querySelector("#pre-loader")
      preloader?.parentNode?.removeChild(preloader);
    })()
    
  },[])


  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      getSlopeWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    [],
  );

  

  return (
    <ThemeProvider theme={theme}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletDialogProvider>
            <Router>
              <Nav/>
              <Switch>
                <Route path="/mint">
                  <Mint
                    candyMachineId={candyMachineId}
                    connection={connection}
                    startDate={startDateSeed}
                    txTimeout={txTimeoutInMilliseconds}
                    rpcHost={rpcHost}
                  />
                </Route>
                <Route path="/gallery">
                  <Gallery/>
                </Route>
                <Route path="/metaverse">
                  <Metaverse/>
                </Route>
                <Route path="/faq">
                  <Faq/>
                </Route>
                <Route path="/roadmap">
                  <Roadmap/>
                </Route>
                <Route path="/licensing-agreement">
                  <LicensingAgreement/>
                </Route>
                <Route path="/404">
                  <NotFound/>
                </Route>
                <Route path="/">
                  <Home
                    candyMachineId={candyMachineId}
                    connection={connection}
                    startDate={startDateSeed}
                    txTimeout={txTimeoutInMilliseconds}
                    rpcHost={rpcHost}
                  />
                </Route>
                <Redirect to="/404" />
              </Switch>
            </Router>
            <Footer/>
          </WalletDialogProvider>
        </WalletProvider>
      </ConnectionProvider>
    </ThemeProvider>
  );
};

export default App;
