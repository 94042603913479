import { useEffect, useState } from "react";

// import gallery from "../assets/gallery.png"

const Gallery = () => {

  return (
    <div className="gallery">
      <div className="section-1">
        <div className="container">
          {/*<img src={gallery} className="title-img"/>*/}
        </div>
      </div>
      
    </div>
  )

}

export default Gallery;