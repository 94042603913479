import { useEffect, useState } from "react";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {faqData} from "../datasets/faqData.js"

import faq from "../assets/faq.png"

const Faq = () => {

  return (
    <div className="faq">
      <div className="section-1">
        <div className="container">
          <img src={faq} className="title-img"/>
          <div className="faq-wrapper">

            {faqData.map((data,index)=>(

              <Accordion key={index} square={true}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon fontSize="large"/>}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <h3>{data.title}</h3>
                </AccordionSummary>
                <AccordionDetails>
                  <p className="text-pre-line">
                    {data.content}
                  </p>
                </AccordionDetails>
              </Accordion>
            ))}

            


          </div>
        </div>
      </div>
      
    </div>
  )

}

export default Faq;