export const whyMintData = [
  
  {
    "image":require("../assets/one.png").default,
    "lottie":require("../assets/DickcatzDAOImage.json"),
    "title":"COMMUNITY DRIVEN",
    "content":"Dickcatz will be community focused. Every Dickcatz NFT holders in the GloryDAO will have a voting right on major project decisions after launch. We want to make sure the best interest of our holders is in alignment with our best interest.",
  },

  {
    "image":require("../assets/two.png").default,
    "lottie":require("../assets/Juice.json"),
    "title":"$JUICE Utility Token",
    "content":"We are planning to harvest $JUICE from Dickcatz in a secretive JUICE factory via staking (maybe also squeezing and stroking). Utilities are exclusive merch, future mint passes, Dickcatz mutation & upgrades, missions, raffles, auctions, and battles etc. More details will be announced soon.",
  },

  {
    "image":require("../assets/three.png").default,
    "lottie":require("../assets/SmartContract.json"),
    "title":"INTELLECTUAL PROPERTY RIGHTS",
    "content":"Non-exclusive license are granted to the Dickcatz NFT buyers, allowing them to use their unique artwork according to the ",
  },

  {
    "image":require("../assets/three.png").default,
    "lottie":require("../assets/DiamondHand.json"),
    "title":"DIAMOND HAND COLLECTORS MAGNET",
    "content":"This is a very long term project. The Dickcatz will set up a reward system to encourage owners to diamond hand their D’s. It will be full of surprises.",
  },

  {
    "image":require("../assets/two.png").default,
    "lottie":require("../assets/PenisFestival.json"),
    "title":"FIRST PENIS FESTIVAL IN METAVERSE",
    "content":"A part of the community fund will be used to develop Dickcatz from 2d into 3d art, buy land in metaverse and build a shrine for you to worship the D for luck! An exclusive community space for Dickcatz owners in the Metaverse. ",
  },
]