import { useEffect, useState } from "react";
import {
  Link,
} from "react-router-dom";

import * as anchor from '@project-serum/anchor';

import Slider from "react-slick";
import Lottie from 'react-lottie';
import Countdown from "react-countdown";
import{ default as animationDataHero } from '../assets/Dickcatz_Lottie_2.json'
import{ default as animationDataLoopHole } from '../assets/DickcatzLoopHole.json'
import{ default as animationDataToTheMoon } from '../assets/ToTheMoonV2.json'
import{ default as animationDataWhiteGoo} from '../assets/WhiteGoo.json'

import sample1 from "../assets/dickcatz01.png"
import sample2 from "../assets/dickcatz02.png"
import sample3 from "../assets/dickcatz03.png"
import sample4 from "../assets/dickcatz04.png"
import sample5 from "../assets/dickcatz05.png"
import sample6 from "../assets/dickcatz06.png"
import sample7 from "../assets/dickcatz07.png"
import sample8 from "../assets/dickcatz08.png"
import sample9 from "../assets/dickcatz09.png"


import one from "../assets/one.png"
import two from "../assets/two.png"
import three from "../assets/three.png"

import barcode from "../assets/barcode.png"

import userPic from "../assets/user-pic.png"


import whatIsIt from "../assets/what-is-it.png"
import timeUnitMint from "../assets/time-until-mint.png"
import mintDetils from "../assets/mint-details.png"
import whyMint from "../assets/why-mint.png"
import team from "../assets/team.png"

import graphic01 from "../assets/graphic-01.png"

import {whyMintData} from "../datasets/whyMintData.js"

import {sampleImageData} from "../datasets/sampleImageData.js"

export interface HomeProps {
  candyMachineId?: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  txTimeout: number;
  rpcHost: string;
}

const Home = (props: HomeProps) => {

  // console.log(props.startDate)

  const [mintActive, setMintActive] = useState(false)
  const [days, setDays] = useState(0)
  const [huors, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)


  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    easing:"linear",
    autoplaySpeed:0,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight:true,
    centerMode: true,
    arrows:false,
    autoplay:true,
    responsive:[
      {
        breakpoint: 1024,
        settings:{
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings:{
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };

  // console.log(process.env.REACT_APP_CANDY_START_DATE)

  // const startDateSeed = (parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10) + 1000000 ) * 1000;

  
  return (
    <div className="home">
      
      <div className="section-head">
        <div className="row-1">
          <div className="text-wrapper">
            {/*<h3>
              Launching November 22, 9PM UTC
            </h3>*/}
            <h2>
              we’re living in a world full of
            </h2>
            <h1>
              DICKCATZ
            </h1>
          </div>
          <div className="animation-wrapper">
            <div className="square">
              <Lottie 
                isClickToPauseDisabled
                options={{
                  loop: true,
                  autoplay: true, 
                  animationData: animationDataHero,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                  }
                }}
              />
            </div>
            
          </div>
        </div>
        <div className="row-2">
          <div className="text-wrapper">
            <h3>
              GET YOUR HANDS DIRTY. MINT PRICE 1 SOL. SUPPLY 10000.
            </h3>
            <h3>
              GET YOUR HANDS DIRTY. MINT PRICE 1 SOL. SUPPLY 10000.
            </h3>
            <h3>
              GET YOUR HANDS DIRTY. MINT PRICE 1 SOL. SUPPLY 10000.
            </h3>
          </div>
        </div>
      </div>

      <div className="section-what-is-it">
        <div className="white-goo">
          <Lottie 
            isClickToPauseDisabled
            options={{
              loop: true,
              autoplay: true, 
              animationData: animationDataWhiteGoo,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
              }
            }}
          />
        </div>
        <div className="container">
          <div className="column-text">
            <img src={whatIsIt} className="title-img"/>
            <p className="text-lg">
              Dickcatz is a community-focused generative NFT project on the Solana blockchain. Each Dickcat is one of a kind, uniquely created dick-shaped looking cats using over 180 traits. Some dickcatz are rarer than others. The rarer dickcatz will probably worth more than the others. Good luck in collecting your D’s.
            </p>
          </div>
          <div className="column-image">
            {/*<img src={graphic01} />*/}
            <div className="square">
              <Lottie 
                isClickToPauseDisabled
                options={{
                  loop: true,
                  autoplay: true, 
                  animationData: animationDataLoopHole,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-time-unit-mint">
        <div className="container">
          <img src={timeUnitMint} className="title-img"/>
          {!mintActive && (
            <div className="counter-wrapper">
              <div className="digital-wrapper">
                {/*<h3>
                  <Countdown
                    date={startDateSeed}
                    renderer={props => 
                      (props.days)
                    }
                  />
                </h3>*/}
                <h3>
                  T
                </h3>
                <h4>
                  DAYS
                </h4>
              </div>
              <div className="digital-wrapper">
                {/*<h3>
                  <Countdown
                    date={startDateSeed}
                    renderer={props => 
                      (props.hours)
                    }
                  />
                </h3>*/}
                <h3>
                  B
                </h3>
                <h4>
                  HRS
                </h4>
              </div>
              <div className="digital-wrapper">
                {/*<h3>
                  <Countdown
                    date={startDateSeed}
                    renderer={props => 
                      (props.minutes)
                    }
                  />
                </h3>*/}
                <h3>
                  C
                </h3>
                <h4>
                  MINS
                </h4>
              </div>
              {/*<div className="digital-wrapper">
                <h3>
                  <Countdown
                    date={startDateSeed}
                    renderer={props => 
                      (props.seconds)
                    }
                    onComplete={()=>{
                      setMintActive(true)
                    }}
                  />
                </h3>
                <h4>
                  SECS
                </h4>
              </div>*/}
            </div>
          )}
          {mintActive && (
            <>
              <h2 className="title-lg text-center">
                It's Live Now
              </h2>
              <p className="text-md text-center">
                Click the link below to mint
              </p>
              <Link to="/mint" className="button-md">
                MINT NOW
              </Link>
            </>
          )}
        </div>
      </div>

      
      <div className="section-mint-details">
        <div className="container">
          <img src={mintDetils}  className="title-img"/>
          <p className="text-lg text-center">
            The Dickcatz collection will launch in Q2 2022, exact date TBC. Presale will be available and will be announced soon. Stay tuned!
          </p>
          <div className="fee-and-supply">
            <div className="supply title-md color-pr text-center">
              SUPPLY
              <br/>10000
            </div>
            <div className="fee title-md color-pr text-center">
              MINT PRICE
              <br/>1 SOL
            </div>
          </div>
        </div>
        
      </div>

      <div className="section-slider">
        <div className="slider-wrapper">
          <Slider {...settings}>
            {sampleImageData.map((data,index)=>(
              <div className="image-wrapper" key={index}>
                <div className="image">
                  <img src={data.image} />
                </div>
                <div className="barcode">
                  <img src={barcode}/>
                  <h4 className="text-md text-center">
                    {data.caption}
                  </h4>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      
      <div className="section-why-mint">
        <div className="container">
          <img src={whyMint} className="title-img"/>
          {whyMintData.map((data, index)=>(
            <div key={index}>
              <div className="row">
                <div className="column-image">
                  {/*<img src={data.image} />*/}
                  <Lottie 
                    isClickToPauseDisabled
                    options={{
                      loop: true,
                      autoplay: true, 
                      animationData: data.lottie,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice'
                      }
                    }}
                  />
                </div>
                <div className="column-text">
                  <h2 className="title-md">
                    {data.title}
                  </h2>
                  <p className="text-lg">
                    {data.content}
                    {index === 2 && (
                      <>
                        <Link to="/licensing-agreement" className="text-lg bold">
                          LICENSING AGREEMENT
                        </Link>
                        .
                      </>
                    )}
                    {index === 4 && (
                      <>
                        <a href="https://theculturetrip.com/asia/japan/articles/how-to-celebrate-kanamara-matsuri-tokyos-penis-festival/" className="text-lg bold">
                          See real world penis festival here
                        </a>
                        
                      </>
                    )}
                  </p>
                </div>
              </div>
              {whyMintData.length > index + 1 && (
                <hr/>
              )}
              
            </div>
          ))}
          
        </div>
        
        <Link to="/roadmap" className="button-md">
          VISIT ROADMAP
        </Link>

      </div>

      <div className="section-to-the-moon">
        <div className="container">
          <Lottie 
            isClickToPauseDisabled
            options={{
              loop: true,
              autoplay: true, 
              animationData: animationDataToTheMoon,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
              }
            }}
          />
        </div>
      </div>

      <div className="section-team">
        <img src={team} className="title-img"/>
        <div className="team-wrapper">
          <div className="container">
            <div className="member-wrapper">
              <img src={sample7}/>
              <h2 className="title-md text-center">
                ALPHA D
              </h2>
              <h3 className="text-md text-center">
                ARTIST
              </h3>
              <p className="text-sm">
                The main brain of the Dickcatz Project. A character designer who launched several collections of blind box figures for his own creation, along with a collection of merch.
              </p>
            </div>
            <div className="member-wrapper">
              <img src={sample8}/>
              <h2 className="title-md text-center">
                SAFE D
              </h2>
              <h3 className="text-md text-center">
                DEVELOPER
              </h3>
              <p className="text-sm">
                Digital agency owner and experienced web developer. Always sitting in front of his computer grinding. His clients includes big real estate players.
              </p>
            </div>
            <div className="member-wrapper">
              <img src={sample9}/>
              <h2 className="title-md text-center">
                LIKMA D
              </h2>
              <h3 className="text-md text-center">
                COMMUNITY GUY
              </h3>
              <p className="text-sm">
                Lovely guy. He doesn’t sleep much. Very detail oriented and loves drinking very much.  Talk to him on Discord. He’s very friendly.
              </p>
            </div>
          </div>
        </div>
      </div>



    </div>
  )

}

export default Home;