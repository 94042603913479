import { useEffect, useState } from "react";
import {
  Link,
  useLocation,
} from "react-router-dom";

import ReactGA from "react-ga4";

import moment from 'moment'

import logo from "../assets/logo.png";
import twitterBlack from "../assets/twitter-black.svg";
import discordBlack from "../assets/discord-black.svg";
import twitterYellow from "../assets/twitter-yellow.svg";
import discordYellow from "../assets/discord-yellow.svg";
import instagramBlack from "../assets/instagram-black.svg";
import instagramYellow from "../assets/instagram-yellow.svg";
import CloseIcon from '@material-ui/icons/Close';

const Nav = () => {
  const [hamburgerActive, setHamburgerActive] = useState(false)
  const hamburgerClick = (state:any) => {
    setHamburgerActive(state)
  }

  // console.log(moment((parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10) + 1000000 ) * 1000).format())

  // let event = {
  //   title: 'Dickcatz Minting',
  //   description: 'This is the sample event provided as an example only',
  //   location: 'https://dickcatz.com',
  //   startTime: moment((parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10) + 1000000 ) * 1000).format(),
  //   endTime: moment((parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10) + 1000000 ) * 1000).add(1, 'days').format()
  // };

  let location = useLocation();

  useEffect(()=>{
    ReactGA.initialize("G-WCYDYE0MHW");
  },[])

  useEffect(()=>{
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  },[location.pathname])

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[location.pathname])


  return (
    <div className="nav">
      <div className="column-logo">
        <Link to="/">
          <img src={logo}/>
        </Link>
      </div>
      <div className="column-hamburger">
        <button 
          className={"hamburger hamburger--squeeze " + (hamburgerActive? "is-active " : " ")}
          onClick={()=>{hamburgerClick(true)}}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
      </div>
      <div className="column-nav">
        {/*<Link to="/gallery" className="page-link">
          GALLERY
        </Link>*/}
        <Link to="/metaverse" className="page-link">
          METAVERSE
        </Link>
        <Link to="/faq" className="page-link">
          FAQ
        </Link>
        <Link to="/roadmap" className="page-link">
          ROADMAP
        </Link>
        <div className="social-media-wrapper">
          <a href="https://twitter.com/dickcatz" className="social-media-link" target="_blank">
            <img src={twitterBlack}/>
          </a>
          <a href="https://discord.gg/dickcatz" className="social-media-link" target="_blank">
            <img src={discordBlack}/>
          </a>
          <a href="https://www.instagram.com/dickcatz/" className="social-media-link" target="_blank">
            <img src={instagramBlack}/>
          </a>
        </div>
        <div className="mint-wrapper">

        </div>
      </div>
      <div className={"mobile-overlay " + (hamburgerActive? "is-active " : " ")}>
        <CloseIcon 
          style={{color:"#FEE275", fontSize: 40}}
          onClick={()=>{hamburgerClick(false)}}
        />
        <div className="row">
          {/*<Link to="/" className="logo">
            <img src={logo} onClick={()=>{hamburgerClick(false)}}/>
          </Link>*/}
        </div>
        <div>
          <div className="row">
            <Link to="/" className="page-link" onClick={()=>{hamburgerClick(false)}}>
              HOME
            </Link>
          </div>
          {/*<div className="row">
            <Link to="/gallery" className="page-link" onClick={()=>{hamburgerClick(false)}}>
              GALLERY
            </Link>
          </div>*/}
          <div className="row">
            <Link to="/metaverse" className="page-link" onClick={()=>{hamburgerClick(false)}}>
              METAVERSE
            </Link>
          </div>
          <div className="row">
            <Link to="/faq" className="page-link" onClick={()=>{hamburgerClick(false)}}>
              FAQ
            </Link>
          </div>
          <div className="row">
            <Link to="/roadmap" className="page-link" onClick={()=>{hamburgerClick(false)}}>
              ROADMAP
            </Link>
          </div>
        </div>
        <div className="row">
          <a href="https://twitter.com/dickcatz" className="social-media-link" target="_blank" onClick={()=>{hamburgerClick(false)}}>
            <img src={twitterYellow}/>
          </a>
          <a href="https://discord.com/invite/NkPR4Yyyqw" className="social-media-link" target="_blank" onClick={()=>{hamburgerClick(false)}}>
            <img src={discordYellow}/>
          </a>
          <a href="https://www.instagram.com/dickcatz/" className="social-media-link" target="_blank" onClick={()=>{hamburgerClick(false)}}>
            <img src={instagramYellow}/>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Nav;