export const faqData = [
  {
    "title":"What is Dickcatz?",
    "content":"Dickcatz is a community-focused generative NFT project on the Solana blockchain. Each Dickcat is one of a kind, uniquely created dick-shaped looking cats using over 230 traits. They are all here to act like a dick and give you troubles. Some dickcatz are rarer than others. The rarer dickcatz will probably worth more than the others. Good luck in collecting your D’s.",
  },
  {
    "title":"Why we started this?",
    "content":"Simply because we don’t think we have drawn enough dicks in the past. We refuse to grow up, and we want like-minded people like you to support us, draw with us, hang with us and stay foolish with us. It’s the process we love. It brings joy drawing the Dickcatz & coming up with Dickcatz ideas on how we could bring fun to this project and benefit our holders.",
  },
  {
    "title":"Who is it for?",
    "content":"If you like cats, it’s for you. If you like dicks, it’s for you. If you have a dick, it's for you. If you don't have a dick, it's for you. If you are a fun person, it’s for you. If you like to show off to your friend, it's for you. If you drew a penis before, it’s for you. If you refuse to grow up, it’s for you.",
  },
  {
    "title":"Wen mint?",
    "content":"Date & Time TBA.",
  },
  {
    "title":"Is there a presale?",
    "content":"Yes, details TBA. You need to be in our whitelist for pre-sale. Please follow our Discord server for more info.",
  },
  {
    "title":"What is the mint price?",
    "content":"1 SOL (Make sure you have 1.1 SOL in your wallet to cover the transaction fee, just to be safe)",
  },
  {
    "title":"Total supply? How many can I mint?",
    "content":"10,000 Dickcatz in total. You can mint up to 3 Dickcatz per wallet.",
  },
  {
    "title":"How much are the royalties?",
    "content":"6.9%",
  },
  {
    "title":"How do I check my rarity?",
    "content":"Our rarity chart will be out soon after mint.",
  },
  {
    "title":"How do I mint?",
    "content":"Step 1 - Get yourself some SOL on any respectable exchange such as FTX, Coinbase or Binance. Just to be safe, you will need 1 SOL to make sure you cover transaction fees. \n\nStep 2 - After you bought your SOL, set up a Phantom wallet on your computer. Transfer the SOL you bought to your Phantom wallet and now you ready to get yourself some Dickcatz when mint day comes.\n\nStep 3 - We will announce the mint link on our socials on mint day. You can then connect your Phantom wallet on our website and grab your Dickcatz to quench your thirst!",
  },
  {
    "title":"How to sell?",
    "content":"Our goal is to seek people who are genuinely interested in collecting our D’s for very long term. We do not encourage you sell your dickcatz for a quick flip. However if you must, you can list your Dickcatz on secondary markets after the launch. Secondary marketplaces will be announced then.",
  },
  {
    "title":"$JUICE?",
    "content":"We are planning to harvest $JUICE from Dickcatz in a secretive JUICE factory via staking (maybe also squeezing and stroking). Utilities are exclusive merch, future mint passes, Dickcatz mutation & upgrades, missions, raffles, auctions, and battles etc. More details will be announced soon.",
  },
  {
    "title":"Rewards to diamond hands?",
    "content":"The longer you hold onto your D's, the more surprises you will get. Our goal is to seek people who are genuinely interested in collecting our D’s for very long term. We do not encourage you sell your Dickcatz for a quick flip. However if you must, you can list your Dickcatz on secondary markets after the launch.",
  },
  {
    "title":"How to avoid scammers?",
    "content":"Do not click into any links in your DMs. We will not DM you in any circumstances. Always use a burner wallet when you mint to keep your risk at minimum.",
  },
  {
    "title":"Community funds?",
    "content":"20% of the minting revenue and 33% secondary market royalties will go into our Dickcatz community fund. \n\nWe are trying to build a brand here. We will use the funds to hire the right people and collaborate with partners in order to grow and strengthen our community and get our D’s ready into metaverse.",
  },
]