import { useEffect, useState } from "react";

import roadmap from "../assets/roadmap.png"
import {roadMapData} from "../datasets/roadMapData.js"
import done from "../assets/done.svg"
import not from "../assets/not.svg"

const Roadmap = () => {

  return (
    <div className="roadmap">
      <div className="section-1">
        <div className="container">
          <img src={roadmap} className="title-img"/>
        </div>
      </div>
      <div className="section-phase">
        <div className="container">
          <hr/>
          <h2 className="title-md text-center">
            PHASE I - PRE-LAUNCH
          </h2>
          <div className="steps-wrapper">
            {roadMapData.phase1.steps.map((step,index)=>(
              <div className="step" key={index}>
                <div className="icon">
                  {step.completed ? 
                    <img src={done}/>
                  :
                    <img src={not}/>
                  }
                  {roadMapData.phase1.steps.length > index + 1 && (
                    <div className="stroke"/>
                  )}
                  

                </div>

                <div className="text">
                  <h3>
                    {step.title}
                  </h3>
                </div>

              </div>
            ))}
            
          </div>
        </div>
      </div>

      <div className="section-phase">
        <div className="container">
          <hr/>
          <h2 className="title-md text-center">
            PHASE II - POST-LAUNCH
          </h2>
          <div className="steps-wrapper">
            {roadMapData.phase2.steps.map((step,index)=>(
              <div className="step" key={index}>
                <div className="icon">
                  {step.completed ? 
                    <img src={done}/>
                  :
                    <img src={not}/>
                  }
                  {roadMapData.phase2.steps.length > index + 1 && (
                    <div className="stroke"/>
                  )}
                  

                </div>

                <div className="text">
                  <h3>
                    {step.title}
                  </h3>
                </div>

              </div>
            ))}
            
          </div>
        </div>
      </div>


      <div className="section-phase">
        <div className="container">
          <hr/>
          <h2 className="title-md text-center">
            PHASE III - LONG TERM GOAL
          </h2>
          <div className="steps-wrapper">
            {roadMapData.phase3.steps.map((step,index)=>(
              <div className="step" key={index}>
                <div className="icon">
                  {step.completed ? 
                    <img src={done}/>
                  :
                    <img src={not}/>
                  }
                  {roadMapData.phase3.steps.length > index + 1 && (
                    <div className="stroke"/>
                  )}
                  

                </div>

                <div className="text">
                  <h3>
                    {step.title}
                  </h3>
                </div>

              </div>
            ))}
            
          </div>
        </div>
      </div>


    </div>
  )

}

export default Roadmap;