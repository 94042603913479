export const roadMapData = {
  "phase1":{
    "steps":[
      {
        "completed":true,
        "title":"Social Media Launch",
      },
      {
        "completed":true,
        "title":"Official Website Launch",
      },
      {
        "completed":true,
        "title":"JPEGs Ready",
      },
      {
        "completed":true,
        "title":"Whitelist Contests & Giveaways",
      },
      {
        "completed":false,
        "title":"1 of 1s Reveal",
      },
      {
        "completed":false,
        "title":"Pre-sale Opens to OG Dickcatz",
      },
    ]
  },

  "phase2":{
    "steps":[
      {
        "completed":false,
        "title":"Rarity Tools",
      },
      {
        "completed":false,
        "title":"Listing on Secondary Marketplace",
      },
      {
        "completed":false,
        "title":"Establish the One & Only Dickcatz GloryDAO",
      },
      {
        "completed":false,
        "title":"$JUICE Utility Token",
      },
      {
        "completed":false,
        "title":"Initial LP Funding",
      },
      {
        "completed":false,
        "title":"Future Mint Passes",
      },
      {
        "completed":false,
        "title":"Collaborations, Giveaways & Airdrops",
      },
      {
        "completed":false,
        "title":"NFTs and 1 of 1 Auctions",
      },
      {
        "completed":false,
        "title":"Talent Hiring (Developers, Artists, Animators, Marketers, Moderators)",
      },
      {
        "completed":false,
        "title":"Be Ready for Mass Metaverse Adoption (3D Character Models)",
      },
    ]
  },

  "phase3":{
    "steps":[
      {
        "completed":false,
        "title":"Exclusive Merch Store",
      },
      {
        "completed":false,
        "title":"Animated Shorts & Comic Strips",
      },
      {
        "completed":false,
        "title":"Parties, Events & Meetups in Metaverse!",
      },
      {
        "completed":false,
        "title":"Virtual Penis Festivals!",
      },
      {
        "completed":false,
        "title":"More Partnerships (Real World & Cross Chain)",
      },
    ]
  },
}